/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  line-height: 1.5;
}

.container {
  padding: 0 20px;
}

/*Main App Element*/
.main_App{
  /*background-image: url('../public/images/Background3.png');*/
  background-size: cover; /* Adjust the background size */
  background-repeat: no-repeat;
  background-position: center center;
}
.main_App > img{
  position: fixed;
  z-index: -10;
  top: 0;
  transform: rotate(180deg);
  width: 100%;
  height: 100%;
}

/* Header Styles */
.header {
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 20px 0;
}

.navbar {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 1s ease;
}

.fixed-navbar {
  background-color: #000;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  color: #fff;
  padding: 10px 0; /* Smaller padding when fixed */
  z-index: 100; /* Ensure it's above other content */
  transition: all 1s ease;
}

.navbar-brand {
width: 50%;
display: flex;
flex-direction: column;
align-items: center;

}
.navbar-brand-fixed {
width: 50%;
display: flex;
flex-direction: column;
align-items: center;
}
.navbar-brand-fixed > img{
  height: 60px;
    width: 35%;
  
}
.navbar-brand > img {
  height: 160px;
  width: 60%;
}
.navbar-nav {
display: flex;
width: 50%;
margin: 0;
padding: 0;
/*justify-content: center;*/
align-items: center;
}
.navbar-nav > ul 
{
  list-style: none;
  display: flex;
  width: 100%;
}
.nav-item {
flex: 1;
margin: 0 2px;
max-width: 120px;

}

.nav-link {
  text-align: center;
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  padding: 6px 6px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  white-space: nowrap;
  width: 100%;
  max-width: 110px;
}
.nav-link:hover {
  background-color: #FFBD59; /* Yellow background on hover */
  color: #000; /* Black text color on hover */
}

/* Active link style (when clicked) */
.nav-link.active {
  background-color: #FFBD59; /* Yellow background on click */
  color: #000; /* Black text color on click */
}
@media (max-width: 1020px) and (min-width: 700px) {
  .navbar-nav {
    /*flex-wrap: wrap;*/
    justify-content: center;
  }

  .navbar-nav > ul 
{
  list-style: none;
  display: flex;
  width: 100%;
  padding-left: 0px;
}
  .navbar-brand {
    width: 50%;
    min-width: 350px;
  }
  .navbar-brand > img {
    height: 145px;
    width: 70%;
  }  

  .nav-item {
    margin: 8px 0; /* Space between each nav-item */
    max-width: 75px;
    display: flex;
    align-items: center;
  }

  .nav-link {
    font-size: 0.92rem;
    /*padding: 8px 14px;*/
    padding: 6px 0px;
    margin: 0;
  }

}
@media (max-width: 699px) {
 
 .menu-button {
  background-color: #FFBD59;
  color: black;
  position: fixed;
  font-size: 1.2rem;
  top: 66px;
  right: 18px;
  width: 110px;
  height: 38px;
  border: none;
  border-radius: 7px;
  transition: all 1s ease;
}

.menu-button:hover{
  background-color: black;
  color: white;
  transition: all 1s ease;
}

.fixed-navbar .menu-button
{
  top: 18px;
}



/* Adjust the container's styles */
.container {
  display: flex;
  flex-direction: column;
  position: relative; /* Ensure relative positioning for absolute elements */
}
.navbar-brand {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navbar-brand > img {
  height: 150px;
  width: 80%;
  margin-left: 40px;
}
.navbar-brand-fixed {
  width: 70%;
}
.navbar-brand-fixed  > img {
  width: 60%;
  margin-left: 0;
}

.nav-link{
    margin-bottom: 20px;
    /* max-width: 110px; */
}




.dropdown-links {
  list-style: none;
  background-color: #000000;
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  width: 120px;
  padding: 10px 20px;
  margin-top: 0px;
  transition: all 1s ease;
  height: 800px;
}
 .displayN{
  display: none;
  transition: all 1s ease;
 }

}


.header-content {
  text-align: center;
}

.heading {
  font-size: 3rem;
  margin-bottom: 10px;
}

.subheading {
  font-size: 1.5rem;
  color: #FFBD59;
  margin-bottom: 30px;
}

.btn {
  background-color: #ffd700;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.btn:hover {
  background-color: #ffc800;
}


/* About Section Styles */
.about {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0);
  padding: 60px 0;
 /* Set a fixed height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
}
.about > .container{
  
  align-self: center;
  background-color: #1b1a1a52;
    margin-right: 30px;
    margin-left: 30px;
    border-radius: 20px;
}

.about-content {
  text-align: center;
  margin-bottom: 40px;
}
.about-content > p{
  color: #e4c9a1;
}

.about >.container > div
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-heading {
  align-self: center;
  color: #ffa720;
  font-size: 2rem;
  margin-bottom: 10px;
}

.section-subheading {
  color: #FFBD59;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.about-image {
  height: 160px;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.085);
}

/* Services Section Styles */
.services {
  padding: 60px 0;
}
.services > .container{
  display: flex;
    flex-direction: column;
}
.services-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.service {
  text-align: center;
  max-width: 300px;
  padding: 20px;
  background-color: #1b1a1a;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  border-radius: 12px;
}

.service-heading {
  color: #FFBD59;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.service-description {
  color: #e4c9a1;
}


/* Products Styles */
.projects {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: 30px;
  min-height: 400px;
  justify-content: center;
  align-content: center;
}



.construction-box {
  background-color: #ffd700; /* Yellow background color */
  color: #000; /* Black text color */
  border-radius: 10px;
  padding: 10px;
  height: 250px; /* Fixed height for all boxes */
}


.construction-box:hover {
  transform: scale(1.05); /* Scale up the box on hover */
}


.construction-image {
  width: 100%;
  height: 80%; /* Adjust the image height within the box */
  object-fit: cover; /* Maintain aspect ratio and cover the box */
  border-radius: 10px;
  transition: transform 0.3s ease; /* Add transition for smooth scaling */
}

.construction-box:hover .construction-image {
  transform: scale(1.2); /* Scale up the image on hover */
}

.dummy-name {
  margin-top: 10px;
  text-align: center;
}



/*Contact-Us*/
.construction-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1b1a1a8a;
  color: #FFBD59;
  border-radius: 5px;
}
.contact-form {
  background-color: #1b1a1a;
  color: #e4c9a1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.contact-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-form textarea {
  resize: vertical;
}

.contact-btn {
  display: inline-block;
  background-color: #ffc800; /* Yellow background */
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.contact-btn:hover {
  background-color: #333;
}



.section-title {
  font-size: 28px;
  color: #FFBD59;
  margin-bottom: 20px;
}

.section-content {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.contact-info-section {
  background-color: #1b1a1a;
  
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.contact-info {
  color: #e4c9a1;
  margin-bottom: 15px;
}
span.contact-info{
  font-size: 20px;
}
.location-map {
  border: 2px solid #000;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.map-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.map-overlay p {
  margin: 0;
}



/* Footer Styles */
.footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}


